import { formatRangeValues, formatThousands } from '~/utils/functions'

export default {
    s: {
        value: (val: string) => val,
        format: (val: string): string => `Search: ${val}`,
        query: true,
    },
    stock: {
        value: (val: string) => val,
        format: (val: string): string => `Stock: ${val}`,
        query: true,
    },
    vin: {
        value: (val: string) => val,
        format: (val: string): string => `VIN: ${val}`,
        query: true,
    },
    'location_search_term.lvl0': {
        value: (val: string) => val,
    },
    'location_search_term.lvl1': {
        value: (val: string) => val,
        format: (val: string) => {
            const splitArr: string[] = val.split(' > ').pop()?.split('_') ?? []

            return splitArr.length === 2
                ? `${splitArr[0]}, ${splitArr[1]}`
                : val
        },
    },
    'make_model.lvl0': {
        value: (val: string) => val,
    },
    'make_model.lvl1': {
        value: (val: string) => val,
        format: (val: string) => val.split(' > ').pop(),
    },
    'featured_options.lvl1': {
        value: (val: string) => val,
        format: (val: string) => val.split(' > ').pop(),
    },
    'filters[price.selling]': {
        value: (val: string) => val,
        format: (val: string, range: Record<string, any>) => {
            const { min, max } = formatRangeValues(val.replace(' to ', '-'))

            if (min !== range.min && max !== range.max) {
                return `$${min.toLocaleString()} - $${max.toLocaleString()}`
            } else if (min === range.min) {
                return `Under $${max.toLocaleString()}`
            } else {
                return `Over $${min.toLocaleString()}`
            }
        },
        range: 'price.selling',
    },
    body_style: {
        value: (val: string) => val,
    },
    'filters[miles]': {
        value: (val: string) => val,
        format: (val: string, range: Record<string, any>) => {
            const { min, max } = formatRangeValues(val.replace(' to ', '-'))

            if (min !== range.min && max !== range.max) {
                return `${formatThousands(min)}  - ${formatThousands(max)} Miles`
            } else if (min === range.min) {
                return `Under ${formatThousands(max)} Miles`
            } else {
                return `Over ${formatThousands(min)} Miles`
            }
        },
        range: 'miles',
    },
    fuel: {
        value: (val: string) => val,
    },
    type: {
        value: (val: string) => val,
    },
    condition: {
        value: (val: string) => val,
    },
    transmission_type: {
        value: (val: string) => val,
    },
    drivetrain: {
        value: (val: string) => val,
    },
    tonnage: {
        value: (val: string) => val,
    },
    'bed_length_group.lvl0': {
        value: (val: string) => val,
    },
    'bed_length_group.lvl1': {
        value: (val: string) => val,
        format: (val: string) => `${val.split(' > ').pop()}" Bed`,
    },
    lifted: {
        value: (val: string) => val,
        format: (val: string): string =>
            val === 'true' ? 'Lifted' : 'Not Lifted',
        isNum: true,
    },
    exterior_color_parent: {
        value: (val: string) => val,
    },
    'filters[year]': {
        value: (val: string) => val,
        format: (val: string, range: Record<string, any>) => {
            const { min, max } = formatRangeValues(val.replace(' to ', '-'))

            if (min !== range.min && max !== range.max) {
                return `${min} - ${max}`
            } else if (min === range.min) {
                return `${max} and Older`
            } else {
                return `${min} and Newer`
            }
        },
        range: 'year',
    },
} as Record<string, any>
